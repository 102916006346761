<template>
    <div class="st-main paper-main">
        <div class="st-container">
            <div class="main-l">
                <user-avatar />
                <div class="ai-review paper-score">
                    <div class="paper-term">
                        <p>学期</p>
                        <template v-for="item in debter_arr">
                            <el-radio v-model="debter_id" :label="item.id" :key="item.id">{{ item.name }}</el-radio>
                        </template>
                    </div>
                    <div class="paper-term">
                        <p>测评类型</p>
                        <el-select v-model="term_id" placeholder="请选择类型：" size="small">
                            <template v-for="item in term_arr">
                                <el-option :key="item.id" :label="item.name" :value="item.id"></el-option>
                            </template>
                        </el-select>
                    </div>
                    <div class="paper-term">
                        <p>题型</p>
                        <el-select v-model="typeId" placeholder="请选择测试类型：" size="small">
                            <template v-for="item in type_arr">
                                <el-option :key="item.id" :label="item.name" :value="item.id"></el-option>
                            </template>
                        </el-select>
                    </div>
                    <div class="paper-term">
                        <p>每组训练数</p>
                        <el-radio-group v-model="limit">
                            <el-radio :label="10">10</el-radio>
                            <el-radio :label="20">20</el-radio>
                            <el-radio :label="30">30</el-radio>
                        </el-radio-group>
                    </div>
                    <el-button class="start-btn" type="primary" style="margin-top: 40px;width: 280px;" @click="spacialStartFn" size="small">开始学习
                    </el-button>
                </div>
            </div>
            <!-- 专项训练 -->
            <div class="main-r paper-practice">
                <div class="paper-practice__header">
                    <p>专项训练</p>
                    <div class="paper-practice__progress">
                        <el-progress :stroke-width="10" :show-text="false" :percentage="finishperCentage"></el-progress>
                        <div class="paper-practice__progress--info">
                            <span>已完成</span>
                            <span>
                                {{ finishCount }} / {{ timuCounts }} 小题
                            </span>
                        </div>
                    </div>
                </div>
                <div class="paper-practice__start" v-if="spacialStart == 0">
                    请选择训练类型，点击左边“开始学习”
                </div>
                <div v-if="specialList.length === 0 && spacialStart !== 0" class="paper-practice__start">
                    没有可进行的训练...
                </div>
                <template v-if="spacialStart == 1">
                    <div v-if="spacialStatus != 0">
                        <div class="paper-practice__total">
                            <div>
                                <i class="fa fa-thumbs-o-up"></i>
                                <span>正确</span>
                                <span>{{ rightCount }}</span>
                            </div>

                            <div>
                                <i class="fa fa-thumbs-o-up"></i>
                                <span>错误</span>
                                <span>{{ errorCount }}</span>
                            </div>
                        </div>
                        <div class="paper-practice__empty"></div>
                    </div>
                    <div v-if="spacialStatus == 1">
                        <div>还有 {{ correctIdArr.length }} 大道题未批改，去批改</div>
                        <div>
                            <el-button type="primary" @click="toCorrectFn">去批改</el-button>
                        </div>
                    </div>
                    <div v-if="spacialStatus == 3">
                        <div style="margin-top: 20px;text-align: center;">
                            <el-button type="primary" @click="toCorrectNextFn">继续训练下一轮</el-button>
                        </div>
                    </div>

                    <template v-if="spacialStatus == 0">
                        <template v-for="(item, index) in specialList">
                            <template v-if="index == specialIndex">
                                <div class="paper-practice__body">
                                    <timu :item="item" @setFinish="setFinishCount"></timu>
                                    <template
                                        v-if="item.children == undefined || item.children == null || item.children.length <= 0">
                                    </template>
                                    <template v-else>
                                        <template v-for="(children_item, children_index) in item.children">
                                            <timu :item="children_item" @setFinish="setFinishCount"></timu>
                                        </template>
                                    </template>
                                    <div class="paper-practice__btn" :key="'num_' + index">
                                        <el-button @click="specialUpFn">上一题</el-button>
                                        <el-button type="primary" @click="specialNextFn">下一题</el-button>
                                        <el-button type="primary" @click="save">提交</el-button>
                                    </div>
                                    <!--<div class="paper-practice__btn">
                                <el-button>答案解析</el-button>
                                <el-button type="primary">继续学习</el-button>
                            </div>-->
                                </div>
                            </template>
                        </template>
                    </template>


                    <!--  批改试卷  -->
                    <template v-if="spacialStatus == 2 && correctIdArr.length > 0">
                        <template v-for="(item, index) in specialList">
                            <template v-if="item.is_pigai == 0 && index == correctIdArr[correctIndex]">
                                <div class="topic" :key="index">
                                    <div class="correct-wrapper">
                                        <div style="height: 400px;overflow-y: auto">
                                            <correct-timu :item="item"></correct-timu>

                                            <template
                                                v-if="item.children == undefined || item.children == null || item.children.length <= 0">
                                            </template>
                                            <template v-else>
                                                <template v-for="(children_item, children_index) in item.children">
                                                    <correct-timu :item="children_item"></correct-timu>
                                                </template>
                                            </template>
                                        </div>
                                        <div class="paper-practice__btn">
                                            <el-button @click="correctUp">上一题</el-button>
                                            <el-button type="primary" @click="correctNext">下一题</el-button>
                                            <el-button type="primary" @click="correctSave">提交</el-button>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </template>
                    </template>
                </template>
            </div>
        </div>
    </div>
</template>

<script>

import { mapState } from "vuex";
import LightMusic from "@/views/study/light_music";

import paperResult from "@/views/papers/paper_result"
import paperAnalysis from "@/views/papers/paper_analysis"
import { TYPE_ARR, PRIMARY_TYPE_ARR, AllDEBTER, AllTERM, SERIAL_NUM, JUDGE_ITEM } from "@/common/config/data.js"
import userAvatar from "@/views/study/UserAvatar";
import { img as imgFilter, getSerial as getSerialFilter } from "@/filters/index.js"

import timu from './timu.vue'
import correctTimu from './correct_timu.vue'

let that;

export default {

    components: {
        userAvatar,
        timu,
        correctTimu
    },
    data() {
        return {
            type_arr: TYPE_ARR,//初中题型
            primary_type_arr: PRIMARY_TYPE_ARR,//小学题型
            debter_arr: AllDEBTER,
            term_arr: AllTERM,
            typeId: TYPE_ARR[0]['id'],
            debter_id: '',
            term_id: '',
            limit: 10,

            specialList: [],
            specialTotal: 0,
            specialIndex: 0,
            cateAnswer: [],
            timuAnswer: [],
            timuAnswerError: [],
            timuCount: 0,
            finishCount: 0,
            finishArr:[],
            spacialStatus: 0,//0答题中，1提交（未批改）2，批改中，3批改结束
            spacialStart: 0,//0未开始，1开始
            correctIdArr: [],
            correctIndex: 0,//批改的索引
        }
    },
    created() {
        //this.getUserInfo();
        //this.getSpacial();
        //console.log("user", this.user);
        if(this.nianjiGroupId == 1){
            //小学
            this.type_arr = PRIMARY_TYPE_ARR;
        }
    },
    filters: {
        img: (val) => {
            // msg表示要过滤的数据，a表示传入的参数
            return that.$conf.storage_url + "/" + val;
        },
        getSerial(val) {
            return SERIAL_NUM[val];
        },
    },
    computed: {
        ...mapState({
            token: (state) => state.user.token,
            user: (state) => state.user.user,
        }),
        nianjiGroupId() {
            let group_id = 0;
            let num_arr = [1, 2, 3, 4, 5, 6];
            let num_arr02 = [7, 8, 9];
            //console.log("this.user.nianji_id", this.user.nianji_id);
            if (num_arr.indexOf(this.user.nianji_id) !== -1) {
                group_id = 1;
            } else if (num_arr02.indexOf(this.user.nianji_id) !== -1) {
                group_id = 2;
            } else {
                group_id = 0;
            }
            return group_id;
        },
        finishperCentage() {
            let percentage = 0;
            if(this.timuCounts>0){
                percentage = (this.finishCount/this.timuCounts) * 100
            }
            return percentage;
        },
        rightCount() {
            return this.timuAnswer.length;
        },
        errorCount() {
            return this.timuAnswerError.length;
        },
        timuCounts() {
            return this.getTimuCount(this.specialList);
        }
    },
    methods: {
        initData() {
            this.specialList = [];
            this.specialTotal = 0;
            this.specialIndex = 0;

            this.cateAnswer = [];
            this.timuAnswer = [];
            this.timuAnswerError = [];
            this.timuCount = 0;
            this.finishCount = 0;
            this.spacialStatus = 0;//0答题中，1提交（未批改）2，批改中，3批改结束
            this.spacialStart = 0;//0未开始，1开始
            this.correctIdArr = [];
            this.correctIndex = 0;//批改的索引
            this.finishArr = [];
        },
        spacialStartFn() {
            this.initData();
            this.getSpacial();
            this.spacialStart = 1;
        },
        toCorrectNextFn() {
            this.initData();
            //继续下一轮学习
            this.getSpacial();
            this.spacialStart = 1;
        },
        getSpacial() {
            console.log(this.debter_id, this.term_id, this.typeId)
            let data = {
                token: this.token,
                debter_id: this.debter_id,
                term_id: this.term_id,
                timu_type: this.typeId,
                pageSize: this.limit
            }
            this.axios.post('/api/spacial/index', data).then((res) => {
                if (res.data.status_code == 200) {
                    this.specialList = res.data.data.list;
                    this.specialTotal = res.data.data.total;
                    console.log(this.specialList)
                }
            })
        },
        specialUpFn() {
            if (this.specialIndex > 0) {
                this.specialIndex--;
            }
        },
        specialNextFn() {

            if (this.specialIndex < this.specialList.length - 1) {
                this.specialIndex++;
            }
            console.log("specialNextFn:" + this.specialIndex);
        },
        getUserInfo() {
            let than = this;
            this.loading = true;
            //let danyuan_ids = this.danyuanCheckStr;
            let params = {
                token: this.token,
            };
            //console.log(params);
            this.axios
                .post("/api/user/get_user_info", params)
                .then((res) => {
                    let data = res.data;
                    //console.log("get_user_info");
                    //console.log(data, "用户信息");
                    if (data.status_code == 200) {
                        //console.log(data.data);
                        this.$store.commit("setUser", data.data);
                    } else if (res.data.status_code == 202) {
                    } else {
                        //this.$message(res.data.msg)
                    }
                })
                .catch((err) => {
                    //this.loading = false
                });
        },
        saveDo() {
            //console.log("this.timuAnswer", this.timuAnswer);
            //console.log("this.cateAnswer", this.cateAnswer);
            //console.log(this.debter_id, this.term_id, this.typeId)
            let data = {
                user_id:this.user.id,
                token: this.token,
                mold_answer: this.cateAnswer,
                timu_answer: this.timuAnswer,
                timu_type: this.typeId,
            }
            this.axios.post('/api/spacial/save', data).then((res) => {
                if (res.data.status_code == 200) {
                    if(this.correctIdArr.length>0){
                        this.spacialStatus = 1;
                    }else{
                        this.spacialStatus = 3;
                    }

                    console.log(this.specialList)
                }
            })
        },
        save() {
            //console.log("specialList", this.specialList);
            /*for(let i =0;this.specialList.length;i++){
            }*/

            //自动批改
            /*this.specialList.forEach((item, index) => {
                item.is_pigai = 0;
                item.timu.forEach((item_timu) => {
                    if (item_timu.type == 1 || item_timu.type == 2) {
                        item.is_pigai = 1;//是否批改
                        item_timu.value.forEach((item_value) => {
                            if (item_value.right_answer == item_value.answer && item_value.right_answer != null) {
                                item_value.is_right = 1;
                            } else {
                                item_value.is_right = 0;
                            }
                        });
                    } else if (item_timu.type == 3) {//判断
                        if (item_timu.right_answer == item_timu.answer && item_timu.right_answer != null) {
                            item_timu.is_right = 1;
                        }
                    } else if (item_timu.type == 4) {

                    } else if (item_timu.type == 5) {

                    }
                });

                item.children.forEach((item_chil, item_index) => {
                    item_chil.timu.forEach((ch_item_timu) => {
                        if (ch_item_timu.type == 1 || ch_item_timu.type == 2) {//单选，选择填空
                            ch_item_timu.value.forEach((ch_item_value) => {
                                if (ch_item_value.right_answer == ch_item_value.answer && ch_item_value.right_answer != null) {
                                    ch_item_value.is_right = 1;
                                } else {
                                    ch_item_value.is_right = 0;
                                }
                            });
                        } else if (ch_item_timu.type == 3) {//判断
                            if (ch_item_timu.right_answer == ch_item_timu.answer && ch_item_value.right_answer != null) {
                                ch_item_timu.is_right = 1;
                            }
                        } else if (ch_item_timu.type == 4) {//问答

                        } else if (ch_item_timu.type == 5) {//写作

                        }
                    });
                })

            })*/
            this.autoCorrect(this.specialList);
            console.log("this.specialList", this.specialList);

            this.getAnswer(this.specialList);
            this.getNoCorrect();//设置批改的题目

            this.saveDo();

            console.log("this.timuAnswer", this.timuAnswer);
            console.log("this.cateAnswer", this.cateAnswer);
        },
        correctSave() {
            this.spacialStatus = 3;//批改结束
            this.timuAnswer = [];
            this.timuAnswerError = [];
            this.cateAnswer = [];
            this.getAnswer(this.specialList);

        },
        autoCorrect(specialList) {
            //自动批改
            specialList.forEach((item, index) => {
                item.is_pigai = 0;
                item.timu.forEach((item_timu) => {
                    if (item_timu.type == 1 || item_timu.type == 2) {
                        item.is_pigai = 1;//是否批改
                        item_timu.value.forEach((item_value) => {
                            if (item_value.right_answer == item_value.answer && item_value.right_answer != null) {
                                item_value.is_right = 1;
                            } else {
                                item_value.is_right = 0;
                            }
                        });
                    } else if (item_timu.type == 3) {//判断
                        item.is_pigai = 1;//是否批改
                        if (item_timu.right_answer == item_timu.answer && item_timu.right_answer != null) {
                            item_timu.is_right = 1;
                        } else {
                            item_timu.is_right = 0;
                        }
                    } else if (item_timu.type == 4) {
                        //this.correctIdArr.push(index);

                    } else if (item_timu.type == 5) {
                        //this.correctIdArr.push(index);
                    }
                });
                //console.log("item",item);
                //console.log("item.children",item.children);
                if (item.children != undefined && item.children.length > 0) {
                    this.autoCorrect(item.children);
                }
            })
        },
        getAnswer(specialList) {
            specialList.forEach((item, index) => {
                if (item.children != undefined && item.children.length > 0) {
                    this.getAnswer(item.children);//回调
                } else {
                    let all_rigth = 1;//判断是否全对
                    item.timu.forEach((item_timu) => {//题目
                        if (this.spacialStatus == 3) {
                            //手动批改操作完成
                            let timuAnswer = {};
                            timuAnswer.id = item_timu.id;
                            timuAnswer.m_uuid = item.uuid;//栏目uuid
                            if (item_timu.is_right == 1) {
                                timuAnswer.is_right = 1;
                                this.timuAnswer.push(timuAnswer);
                            } else {
                                all_rigth = 0;
                                timuAnswer.is_right = 0;
                                this.timuAnswerError.push(timuAnswer);
                            }
                        } else {
                            //手动批改前
                            if (item_timu.type == 1 || item_timu.type == 2 || item_timu.type == 3) {
                                let timuAnswer = {};
                                timuAnswer.id = item_timu.id;
                                timuAnswer.m_uuid = item.uuid;//栏目uuid
                                if (item_timu.is_right == 1) {
                                    timuAnswer.is_right = 1;
                                    this.timuAnswer.push(timuAnswer);
                                } else {
                                    all_rigth = 0;
                                    timuAnswer.is_right = 0;
                                    this.timuAnswerError.push(timuAnswer);
                                }
                            } else {
                                all_rigth = 0;
                            }
                        }
                    });
                    if (all_rigth == 1) {
                        //获取对或错的题
                        let cateAnswer = {};
                        cateAnswer.uuid = item.uuid;
                        cateAnswer.is_right = 1;
                        cateAnswer.title = item.title;
                        this.cateAnswer.push(cateAnswer);
                    }
                }
            });

            //获取大分类下是否全对
            specialList.forEach((item, index) => {
                if (item.children != undefined && item.children.length > 0) {
                    //this.getAnswer(item.children);//回调
                    let all_rigth = 1;//判断是否全对
                    let is_pigai = 1;//判断大类是否需要批改
                    item.children.forEach((chil_item) => {
                        chil_item.timu.forEach((timu_item) => {
                            if (timu_item.is_right == 0) {
                                all_rigth = 0;
                            }
                        })
                        if (chil_item.is_pigai == 0) {
                            is_pigai = 0;
                        }
                    });
                    item.is_pigai = is_pigai;

                    if (all_rigth == 1) {
                        //获取对或错的题
                        let cateAnswer = {};
                        cateAnswer.uuid = item.uuid;
                        cateAnswer.is_right = 1;
                        cateAnswer.title = item.title;
                        this.cateAnswer.push(cateAnswer);
                    }
                }
            });



            /*this.specialList.forEach((item, index) => {
                if (item.children.length == 0) {
                    let all_rigth = 1;//判断是否全对
                    item.timu.forEach((item_timu) => {//题目
                        let timuAnswer = {};
                        timuAnswer.id = item_timu.id;
                        if (item_timu.is_right == 1) {
                            timuAnswer.is_right = 1;
                            this.timuAnswer.push(timuAnswer);
                        } else {
                            all_rigth = 0;
                            timuAnswer.is_right = 0;
                            this.timuAnswerError.push(timuAnswer);
                        }
                    });
                    if (all_rigth == 1) {
                        //获取对或错的题
                        let cateAnswer = {};
                        cateAnswer.uuid = item.uuid;
                        cateAnswer.is_right = 1;
                        cateAnswer.title = item.title;
                        this.cateAnswer.push(cateAnswer);
                    }
                } else {
                    let chil_all_right = 1;//判断是否全对
                    item.children.forEach((item_chil, item_index) => {
                        let ch_all_rigth = 1;
                        item_chil.timu.forEach((ch_item_timu) => {//题目
                            let timuAnswer = {};
                            if (ch_item_timu.is_right == 1) {
                                timuAnswer.id = ch_item_timu.id;
                                timuAnswer.is_right = 1;
                                this.timuAnswer.push(timuAnswer);
                            } else {
                                ch_all_rigth = 0;
                                chil_all_right = 0;
                            }
                        });
                        if (ch_all_rigth == 1) {
                            //获取对或错的题
                            let cateAnswer = {};
                            cateAnswer.uuid = item_chil.uuid;
                            cateAnswer.is_right = 1;
                            this.cateAnswer.push(cateAnswer);
                        }
                    })
                    if (chil_all_right == 1) {
                        //获取对或错的题
                        let cateAnswer = {};
                        cateAnswer.uuid = item.uuid;
                        cateAnswer.is_right = 1;
                        this.cateAnswer.push(cateAnswer);
                    }
                }
            });*/
        },
        getTimuCount(specialList) {
            specialList.forEach((item, index) => {
                if (item.children != undefined && item.children.length > 0) {
                    this.getTimuCount(item.children);//回调
                } else {
                    item.timu.forEach((item_timu) => {//题目
                        this.timuCount++;
                    });
                }
            });
            return this.timuCount;
        },
        toCorrectFn() {
            //去批改
            this.spacialStatus = 2;
            //console.log("correctIdArr",this.correctIdArr);
        },
        getNoCorrect() {
            //获取未批改
            this.specialList.forEach((item, index) => {
                if (item.is_pigai == 0) {
                    this.correctIdArr.push(index);
                }
            });
        },
        correctNext() {
            //this.getNoCorrect();
            if (this.correctIndex < this.correctIdArr.length - 1) {
                this.correctIndex++;
            }
        },
        correctUp() {
            if (this.correctIndex > 0) {
                this.correctIndex--;
            }
        },
        setFinishCount(type=1,num){

            console.log("setFinishCount:"+type);
            console.log("num:"+num);

           // this.finishArr.push(num);

            /*let array2 =  this.finishArr.filter((item,index,array)=>{
                return this.finishArr.indexOf(item,0) === index;
            });
            console.log("去重后："+array2);
            */
            if(type==1){
                if(this.finishCount < this.timuCounts){
                    if(this.finishArr.indexOf(num)==-1){
                        this.finishArr.push(num);
                    }else{
                        //console.log("数值存在");
                    }
                    this.finishCount = this.finishArr.length;
                }
            }else{
                if(this.finishCount >0){
                    //this.finishCount --;
                    for(let i =0;i<this.finishArr.length;i++){
                        if(this.finishArr[i]==num){
                            this.finishArr.splice(i,1);
                             //console.log("删除成功");
                             this.finishCount = this.finishArr.length;
                        }else{
                            //console.log("数值存在");
                        }
                    }
                }
            }
            console.log(this.finishCount );
        }
    }
}
</script>

<style lang="scss" scoped>
$check: #00bfa5;
$error: #f16e6e;

.same-title {
    padding-right: 5px;
    font-size: 18px;
    font-weight: 500;
}
.paper-score {
    padding:.2rem .2rem 0 !important;
    height: 5.6rem;
}
.same-answer {
    font-size: 18px;
}
.paper-term {
    ::v-deep .el-input {
        width: 2.8rem !important;
    }
}
.start-btn {
    width: 2.8rem !important;
}
.paper-practice {
    min-height: 571px !important;
}
.paper-practice__start {
    padding-left: 20px;
    font-size: 20px;
    font-weight: bold;
    font-family: "Microsoft JhengHei UI Light", serif;
}

.paper-practice__body {
    overflow-y: auto;
    height: 510px;
}

.topic-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__wrapper {
        height: 30px;
        line-height: 28px;
        background-color: #ffffff;
        border-radius: 8px;
        //border: solid 2px #0074f8;

        span {
            display: inline-block;
            font-size: 18px;
            font-weight: bold;
        }
    }

    &__text {
        vertical-align: top;
        color: #0074f8;
        font-family: fangsong;
    }
}

.topic-content {
    &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;

        span {
            padding-left: 10px;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: .5px;
            white-space: pre-wrap;
        }

        &>div {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding-right: 10px;
        }

        .answer-right {
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            padding: 15px;
            font-size: 16px;
            background-color: #f7fbff;

            span {
                color: #88a7c6;
                line-height: 25px;
                font-family: SimSun, serif;
            }

            &__select {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 300px;
                height: 40px;
                text-align: center;

                div {
                    display: inline-block;
                    width: 80px;
                    height: 30px;
                    line-height: 30px;
                    text-align: center;
                    border: 1px solid #ccc;
                    cursor: pointer;
                    user-select: none;

                    &:first-child {
                        margin-right: 40px;
                    }
                }

                .s_checked {
                    color: #fff;
                    background-color: $check;
                    border-color: $check;
                }

                .e_checked {
                    color: #fff;
                    background-color: $error;
                    border-color: $error;
                }
            }
        }

        .el-input {
            align-self: flex-end;
            width: 200px;
        }


    }

    &__body {
        .body-title {
            margin: 15px 0;
            font-size: 18px;

            span {
                &:first-child {
                    margin-right: 10px;
                }
            }
            &:nth-child(2) {
                font-size: 22px;
                font-family: 'Times New Roman', Times, serif;
            }
            p {
                span {
                    font-size: 22px !important;
                    font-family: 'Times New Roman', Times, serif;
                }
            }
        }

        .body-list1 {
            margin: 10px 0;
            //padding-left: 24px;

            .list-answer {
                margin-right: 40px;

                &:last-child {
                    margin-right: 0;
                }
            }

            .list-num {
                margin-right: 20px;
                font-size: 18px;
            }

            li {
                display: flex;
                align-items: center;
            }
        }

        .body-list2 {
            padding-left: 24px;

            li {
                display: flex;
                flex-direction: column;
            }

            .el-radio {
                margin-top: 8px;

                &:first-child {
                    margin-top: 0;
                }
            }
        }

        input {
            width: 100%;
            height: 30px;
            line-height: 30px;
            font-size: 18px;
            font-weight: bold;
            border-bottom: 1px solid #0a0a0a;
            outline: none;
        }
    }

    &__analysis {
        .answer-right {
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            padding: 15px;
            font-size: 16px;
            background-color: #f7fbff;

            span {
                color: #88a7c6;
                line-height: 25px;
                font-family: SimSun, serif;
            }

            &__text {
                display: flex;
                justify-content: space-between;

                span {
                    display: inline-block;
                    width: 26px;
                    height: 26px;
                    color: #fff;
                    font-size: 20px;
                    text-align: center;
                    background-color: #00bfa5;
                    border-radius: 50%;
                }
            }

            &__analysis {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .el-input {
                    width: 200px;
                }
            }

            &__select {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 300px;
                height: 40px;
                text-align: center;

                div {
                    display: inline-block;
                    width: 80px;
                    height: 30px;
                    line-height: 30px;
                    text-align: center;
                    border: 1px solid #ccc;
                    cursor: pointer;
                    user-select: none;

                    &:first-child {
                        margin-right: 40px;
                    }
                }

                .s_checked {
                    color: #fff;
                    background-color: $check;
                    border-color: $check;
                }

                .e_checked {
                    color: #fff;
                    background-color: $error;
                    border-color: $error;
                }
            }
        }

    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
    }

    &__img {
        .img-list {
            display: flex;
            justify-content: space-around;

            li {
                display: flex;
                flex-direction: column;
                align-items: center;

                img {
                    margin: 10px 0;
                    width: 140px;
                    height: 100px;
                }

                span {
                    font-size: 16px;
                }
            }
        }

        .answer-list {
            display: flex;
            justify-content: space-around;

            li {
                span {
                    margin-top: 8px;
                    font-size: 24px;
                    text-align: center;

                    &:last-child {
                        display: inline-block;
                        width: 120px;
                        border-bottom: 1px solid #272727;
                    }
                }
            }
        }
    }
}
</style>
