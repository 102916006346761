<template>
    <div>
        <div class="topic-content" v-if="item">
            <span class="topic-content__title" style="color: #0074F8;"> {{ item.title }}</span>
            <span class="topic-content__title">
                {{ item.deputy_title }}
            </span>
            <template v-if="item.children == undefined || item.children == null || item.children.length <= 0">
                <div class="topic-content__img" v-if="item.materials">
                    <template v-if="item.materials.content">
                        <div v-html="item.materials.content"></div>
                    </template>
                    <div v-if="item.materials.audio != '' && item.materials.audio != null">
                        <audio v-if="item.materials.audio != ''" id="audio" ref="audio" controls>
                            <source :src="item.materials.audio | img">
                            <source :src="item.materials.audio | img">
                            您的浏览器不支持 audio 元素。
                        </audio>
                    </div>
                    <ul class="img-list">
                        <template v-for="(c_item, c_index) in item.materials.images">
                            <li :key="c_index">
                                <img :src="c_item.url | img" alt="" />
                                <span>{{ c_index | getSerial }}</span>
                            </li>
                        </template>
                    </ul>
                </div>
                <template v-for="(timu_item, timu_index) in item.timu">
                    <div class="topic-content__body" :key="timu_index">
                        <div class="body-title">
                            <span>{{ timu_item.num }}. </span>
                            <span v-html="timu_item.title"></span>
                        </div>
                    </div>
                    <template v-if="timu_item.type == 1 || timu_item.type == 2">
                        <div class="body-list1" v-for="(value_item, value_index) in timu_item.value"
                            :key="'value_key_' + timu_index + '_' + value_index">
                            <el-radio :key="'item_key_' + item_index" :label="item_item.num" v-model="value_item.answer"
                                v-for="(item_item, item_index) in value_item.item"
                                @change="radioChange(timu_item, value_item, item_item.num)"
                                :disabled="value_item.answer != null ? true : false"
                                :class="[{ 'right-checked': value_item.is_right === 1 && value_item.answer == item_item.num },{ 'right-checked': timu_item.is_answer === 1 && value_item.right_answer == item_item.num }, { 'err-checked': value_item.is_right === 0 && value_item.answer == item_item.num }]">
                                <span class="list-title same-title">
                                    {{ item_item.num | getSerial }}
                                </span>
                                {{ item_item.value }}
                                <img v-if="item_item.img != '' && item_item.img != null" :src="item_item.img | img" alt="" width="60px" height="60px">
                            </el-radio>
                            <div class="answer-area" v-if="timu_item.is_answer == 1 && timu_item.is_right == 0">
                                <span>答案解析：</span>
                                <span>{{ timu_item.analysis }}</span>
                            </div>
                        </div>
                    </template>
                    <template v-if="timu_item.type == 3">
                        <el-radio-group v-model="timu_item.answer">
                            <el-radio :label="judge_item.id" @change="radioChangeStage(timu_item, judge_item.id)"
                                v-for="(judge_item, judge_index) in judge_item" :key="judge_index"
                                :disabled="timu_item.answer != null ? true : false"
                                :class="[{ 'right-checked': timu_item.is_right === 1 && timu_item.answer == judge_item.id },{ 'right-checked': timu_item.is_answer === 1 && timu_item.right_answer == judge_item.id }, { 'err-checked': timu_item.is_right === 0 && timu_item.answer == judge_item.id }]">

                                <span class="list-title same-title">
                                    {{ judge_index | getSerial }}
                                </span>
                                <span class="list-answer same-answer">
                                    {{ judge_item.name }}
                                </span>
                            </el-radio>
                        </el-radio-group>
                        <div class="answer-area" v-if="timu_item.is_answer == 1 && timu_item.is_right == 0">
                            <span>答案解析：</span>
                            <span>{{ timu_item.analysis }}</span>
                        </div>
                    </template>
                    <template v-if="timu_item.type == 4">
                        <div class="topic-content__body">
                            <input type="text" v-model="timu_item.answer"
                                v-on:input="pin_input(timu_item, timu_item.answer,item)" v-on:blur="blur_pin_input(timu_item, timu_item.answer,item)" :disabled="timu_item.is_answer==1">
                        </div>
                        <div class="answer-area" v-if="timu_item.is_answer == 1 && timu_item.is_right == 0">
                            <span>正确答案：</span>
                            <span>{{ timu_item.right_answer }}</span>
                        </div>
                        <div class="answer-area" v-if="timu_item.is_answer == 1 && timu_item.is_right == 0">
                            <span>答案解析：</span>
                            <span>{{ timu_item.analysis }}</span>
                        </div>
                    </template>
                    <template v-if="timu_item.type == 5">
                        <div class="topic-content__body">
                            <el-input type="textarea" v-model="timu_item.answer" :rows="5"
                                v-on:input="pin_input(timu_item, timu_item.answer,item)" placeholder="请输入内容"></el-input>
                        </div>
                    </template>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
import { TYPE_ARR, PRIMARY_TYPE_ARR, DEBTER, TERM, SERIAL_NUM, JUDGE_ITEM } from "@/common/config/data.js"
let that;

export default {
    props: {
        item: { type: Object },
    },
    data() {
        return {
            judge_item: JUDGE_ITEM,
            chooseAudios: {
                src: require("../../assets/audio/check.wav"),
                errSrc: require("../../assets/audio/error.mp3"),
            },
            show_analysis: 0,
        }
    },
    created() {
        //console.log("this.timu", this.timu);
        //this.item = this.timu;
        that = this;
    },
    filters: {
        img: (val) => {
            // msg表示要过滤的数据，a表示传入的参数
            return that.$conf.storage_url + "/" + val;
        },
        getSerial(val) {
            return SERIAL_NUM[val];
        },
    },
    computed: {

    },
    methods: {
        // chooseAudio
        chooseAudio(type = 1) {
            let audio = new Audio();
            audio.controls = false;
            let src = this.chooseAudios.src;
            if (type === 0) {
                src = this.chooseAudios.errSrc;
            }
            audio.src = src;
            audio.play();
        },

        radioChange(timu_item, timu_value_item, num) {
            //console.log("error: ", timu_item, timu_value_item)
            if (timu_value_item.right_answer == num) {
                timu_value_item.is_right = 1;
                this.chooseAudio(1);
                //console.log('right');
            } else {
                timu_value_item.is_right = 0;
                this.chooseAudio(0);
                //console.log('error');
            }

            let is_right = 1;//是否正确
            let is_answer = 1;//是否已做
            for (let i = 0; i < timu_item.value.length; i++) {
                //timu_item.value[i].is_right = 0;
                if (timu_item.value[i].is_right == 0) {
                    is_right = 0;
                }
                if (timu_item.value[i].answer === '' || timu_item.value[i].answer === null) {
                    is_answer = 0;
                    //console.log('is_answer', is_answer);
                }
            }
            timu_item.is_right = is_right;
            if (timu_item.is_right == 1) {
                timu_item.answer_score = timu_item.score;
            }
            timu_item.is_answer = is_answer;
            console.log("timu_item.is_answer", timu_item.is_answer);

            if (is_answer == 1) {
                //console.log("setFinish");
                this.$emit('setFinish', 1, timu_item.id);
            } else {
                this.$emit('setFinish', 0, timu_item.id);
            }
            //单选和选择填空
            //console.log('timu_item', timu_item);
            this.$forceUpdate();
            //console.log('timu_value_item', timu_value_item);
            //console.log('num', num);
        },
        radioChangeStage(timu_item, index) {
            //判断
            if (timu_item.right_answer == index) {
                timu_item.is_right = 1;
                this.chooseAudio(1);
                //console.log('is_right', index);
            } else {
                timu_item.is_right = 0;
                this.chooseAudio(0);
                //console.log('error', index);
            }
            timu_item.is_answer = 1;// 标记为已做
            this.$emit('setFinish', 1, timu_item.id);
            this.$forceUpdate();
            //console.log('timu_item', timu_item);
        },
        pin_input(g_item, answer,item) {
            //console.log('g_item', g_item);
            //console.log("answer", answer);
            /*if (answer == '' || answer == null) {
                g_item.is_answer = 0;
                this.$emit('setFinish', 0, g_item.id);
            } else {
                g_item.is_answer = 1;
                this.$emit('setFinish', 1, g_item.id);
            }
            
            if(item.type == 4){
                console.log();
            }*/

        },
        blur_pin_input(g_item, answer,item){
            //console.log("blur_pin_input");
            if (answer == '' || answer == null) {
                g_item.is_answer = 0;
                this.$emit('setFinish', 0, g_item.id);
            } else {
                g_item.is_answer = 1;
                this.$emit('setFinish', 1, g_item.id);
            }
            
            //词汇
            if(g_item.type == 4 && item.type==6){
                if(g_item.answer == g_item.right_answer && (answer == '' || answer == null)){
                    g_item.is_right = 1;
                    //g_item.answer_score = g_item.score;
                }else{
                    g_item.is_right = 0;
                    //g_item.answer_score = 0;
                }
            }
            this.refresh()

        },
        refresh() {
            //重新渲染页面
            this.$forceUpdate();
        }
    },
}
</script>

<style lang="scss" scoped>
.same-title {
    font-size: 20px;
    font-family: "Times New Roman", serif;
}

.el-radio {
    & ::v-deep .el-radio__label {
        font-size: 20px;
        font-family: "Times New Roman", Times, serif;
    }
}

.topic-content {
    max-width: 925px;
    //overflow-y: auto;
    //height: 540px;
}

.right-checked {
    ::v-deep .el-radio__input.is-disabled+span.el-radio__label {
        color: #fff;
    }

    ::v-deep .el-radio__label {
        margin-left: 5px;
        padding: 2px 10px;
        text-align: center;
        color: #fff;
        background-color: #409EFF;
        border-radius: 13px;
    }

    .same-title {
        color: #fff;
    }

    ::v-deep .el-radio__input.is-disabled.is-checked .el-radio__inner::after {
        background-color: #fff;
    }

    span {
        color: #FFF;
    }

    ::v-deep .el-radio__inner {
        border-color: #409EFF !important;
        background-color: #409EFF !important;
    }

    .same-title {
        color: #fff;
    }

    ::v-deep .el-radio__input.is-disabled.is-checked .el-radio__inner::after {
        background-color: #fff;
    }
}

.err-checked {
    span {
        color: #FFF;
    }

    ::v-deep .el-radio__inner {
        border-color: #FF3F3F !important;
        background-color: #FF3F3F !important;
    }

    ::v-deep .el-radio__label {
        margin-left: 5px;
        padding: 2px 10px;
        text-align: center;
        color: #fff;
        background-color: red;
        border-radius: 13px;
    }

    .same-title {
        color: #fff;
    }

    ::v-deep .is-disabled+span.el-radio__label {
        color: #fff;
    }

    ::v-deep .el-radio__input.is-disabled.is-checked .el-radio__inner::after {
        background-color: #fff;
    }
}

.answer-area {
    margin-top: 10px;
    padding: 8px;
    background-color: #efefef;
    border-radius: 5px;

    span {
        font-family: "Microsoft JhengHei UI Light", serif;

        &:first-child {
            font-size: 16px;
            font-weight: bold;
        }

        &:last-child {
            font-weight: bold;
        }
    }
}
</style>
