<template>
<div class="paper-result">
    <div class="paper-result__contain">
        <div class="paper-result__title">
            <h3>《{{result.kaoshiInfo.title}}》考试</h3>
            <div>
                <span>用时：{{result.yongshi_time}}</span>
                <span>交卷时间：{{result.create_time}}</span>
                <span>考试总分：{{result.kaoshiInfo.score}}分</span>
            </div>
        </div>
        <div class="paper-result__content">
            <span class="paper-result__content--score">{{result.score}}</span>
            <img src="../../assets/images/study/score_bg.png" alt="">
        </div>
        <div class="paper-result__table">
            <ul>
                <li></li>
                <li>总分</li>
                <li>得分</li>
            </ul>
            <ul v-for="(item,index) in result.stageScoreArr" :key="index">
                <li>{{item.name}}</li>
                <li>{{item.item_score}}</li>
                <li>{{item.score}}</li>
            </ul>
            <!--<ul>
                <li>第二部分 语言知识运用</li>
                <li></li>
                <li></li>
            </ul>
            <ul>
                <li>第三部分 阅读理解</li>
                <li></li>
                <li></li>
            </ul>
            <ul>
                <li>第二部分 书面表达</li>
                <li></li>
                <li></li>
            </ul>-->
        </div>
        <el-button type="primary" @click="showAnalysis">查看试卷</el-button>
    </div>
</div>
</template>

<script>
export default {
    props:{
        result: { type: Object }
    },
    data() {
        return {

        }
    },
    created(){
        console.log('result',this.result);
    },
    methods:{
        showAnalysis(){
            this.$emit('close');
        }
    }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/paper_result";
</style>
