<template>
    <div>
        <div class="topic-title" v-if="item.title != '' && item.title != null">
            <div class="topic-title__wrapper">
                <span class="topic-title__text">
                    {{ item.title }}
                </span>
                <span class="topic-title__text">
                    {{ item.deputy_title }}
                </span>
            </div>
        </div>
        <template v-if="item.children == undefined || item.children == null || item.children.length <= 0">
                <div class="topic-content__img" v-if="item.materials">
                    <template v-if="item.materials.content">
                        <div v-html="item.materials.content"></div>
                    </template>
                    <div v-if="item.materials.audio != '' && item.materials.audio != null">
                        <audio v-if="item.materials.audio != ''" id="audio" ref="audio" controls>
                            <source :src="item.materials.audio | img">
                            <source :src="item.materials.audio | img">
                            您的浏览器不支持 audio 元素。
                        </audio>
                    </div>
                    <ul class="img-list">
                        <template v-for="(c_item, c_index) in item.materials.images">
                            <li :key="c_index">
                                <img :src="c_item.url | img" alt="" />
                                <span>{{ c_index | getSerial }}</span>
                            </li>
                        </template>
                    </ul>
                </div>
            </template>
        <template v-if="item.children == undefined || item.children == null || item.children.length <= 0">
        <div class="topic-content" v-for="(timu_item, timu_index) in item.timu">
            <template v-if="timu_item.type == 4 || timu_item.type == 5">
                <span class="topic-content__title"></span>
                <div class="topic-content__img " style="display: none">
                    <ul class="img-list">
                        <li>
                            <img src="" alt="">
                            <span></span>
                        </li>
                    </ul>
                </div>
                <div class="topic-content__title">
                    <span>{{ timu_item.num }}. </span>
                    <span v-html="timu_item.title"></span>
                </div>
                <div class="topic-content__body">
                    答：{{ timu_item.answer }}
                </div>
                <div class="topic-content__analysis">
                    <div class="answer-right">
                        <span class="answer-right__text">
                            <div>
                                正确答案：
                            </div>
                            <div class="answer-right__select">
                                <div :class="[timu_item.is_right === 1 ? 's_checked' : '']"
                                    @click="pdCheck(timu_item, 1)">
                                    正确
                                    <i class="el-icon-check"></i>
                                </div>
                                <div :class="[timu_item.is_right === 0 ? 'e_checked' : '']"
                                    @click="pdCheck(timu_item, 0)">
                                    错误
                                    <i class="el-icon-close"></i>
                                </div>
                            </div>
                        </span>
                        <span class="answer-right__analysis">
                            <span>
                                答案解析：
                            </span>
                            <!--<el-input v-model="answer_score" placeholder="本题得分："></el-input>-->
                        </span>
                    </div>
                </div>
            </template>
        </div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        item: { type: Object },
    },
    methods: {
        pdCheck(item, is_right) {
            //判断题
            if (is_right == 1) {
                item.is_right = 1;
                item.answer_score = item.score;
            } else {
                item.is_right = 0;
                item.answer_score = 0;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
$check: #00bfa5;
$error: #f16e6e;

.correct-wrapper {
    overflow-y: auto;
    height: 380px;
}

.topic-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__wrapper {
        height: 30px;
        line-height: 28px;
        background-color: #ffffff;
        border-radius: 8px;

        span {
            display: inline-block;
            font-size: 18px;
            font-weight: bold;
        }
    }

    &__text {
        vertical-align: top;
        color: #0074f8;
        font-family: fangsong;
    }
}

.topic-content {
    &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;

        span {
            padding-left: 10px;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: .5px;
            white-space: pre-wrap;
        }

        &>div {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding-right: 10px;
        }

        .answer-right {
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            padding: 15px;
            font-size: 16px;
            background-color: #f7fbff;

            span {
                color: #88a7c6;
                line-height: 25px;
                font-family: SimSun, serif;
            }

            &__select {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 300px;
                height: 40px;
                text-align: center;

                div {
                    display: inline-block;
                    width: 80px;
                    height: 30px;
                    line-height: 30px;
                    text-align: center;
                    border: 1px solid #ccc;
                    cursor: pointer;
                    user-select: none;

                    &:first-child {
                        margin-right: 40px;
                    }
                }

                .s_checked {
                    color: #fff;
                    background-color: $check;
                    border-color: $check;
                }

                .e_checked {
                    color: #fff;
                    background-color: $error;
                    border-color: $error;
                }
            }
        }

        .el-input {
            align-self: flex-end;
            width: 200px;
        }


    }

    &__body {
        .body-title {
            margin: 15px 0;
            font-size: 18px;

            span {
                &:first-child {
                    margin-right: 10px;
                }
            }
        }

        .body-list1 {
            margin: 10px 0;
            //padding-left: 24px;

            .list-answer {
                margin-right: 40px;

                &:last-child {
                    margin-right: 0;
                }
            }

            .list-num {
                margin-right: 20px;
                font-size: 18px;
            }

            li {
                display: flex;
                align-items: center;
            }
        }

        .body-list2 {
            padding-left: 24px;

            li {
                display: flex;
                flex-direction: column;
            }

            .el-radio {
                margin-top: 8px;

                &:first-child {
                    margin-top: 0;
                }
            }
        }

        input {
            width: 100%;
            height: 30px;
            line-height: 30px;
            font-size: 18px;
            font-weight: bold;
            border-bottom: 1px solid #0a0a0a;
            outline: none;
        }
    }

    &__analysis {
        .answer-right {
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            padding: 15px;
            font-size: 16px;
            background-color: #f7fbff;

            span {
                color: #88a7c6;
                line-height: 25px;
                font-family: SimSun, serif;
            }

            &__text {
                display: flex;
                justify-content: space-between;

                span {
                    display: inline-block;
                    width: 26px;
                    height: 26px;
                    color: #fff;
                    font-size: 20px;
                    text-align: center;
                    background-color: #00bfa5;
                    border-radius: 50%;
                }
            }

            &__analysis {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .el-input {
                    width: 200px;
                }
            }

            &__select {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 300px;
                height: 40px;
                text-align: center;

                div {
                    display: inline-block;
                    width: 80px;
                    height: 30px;
                    line-height: 30px;
                    text-align: center;
                    border: 1px solid #ccc;
                    cursor: pointer;
                    user-select: none;

                    &:first-child {
                        margin-right: 40px;
                    }
                }

                .s_checked {
                    color: #fff;
                    background-color: $check;
                    border-color: $check;
                }

                .e_checked {
                    color: #fff;
                    background-color: $error;
                    border-color: $error;
                }
            }
        }

    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
    }

    &__img {
        .img-list {
            display: flex;
            justify-content: space-around;

            li {
                display: flex;
                flex-direction: column;
                align-items: center;

                img {
                    margin: 10px 0;
                    width: 140px;
                    height: 100px;
                }

                span {
                    font-size: 16px;
                }
            }
        }

        .answer-list {
            display: flex;
            justify-content: space-around;

            li {
                span {
                    margin-top: 8px;
                    font-size: 24px;
                    text-align: center;

                    &:last-child {
                        display: inline-block;
                        width: 120px;
                        border-bottom: 1px solid #272727;
                    }
                }
            }
        }
    }
}
</style>