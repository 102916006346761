let TYPE_ARR = [{id:1,name:'听力'},{id:9,name:'辨音'},{id:2,name:'单选'},{id:3,name:'完型'},{id:4,name:'阅读'},{id:5,name:'情景交际'},{id:6,name:'词汇'},{id:7,name:'任务型读写'},{id:8,name:'写作'}];
export {TYPE_ARR};
let PRIMARY_TYPE_ARR = [{id:1,name:'听力'},{id:2,name:'选择不同类'},{id:3,name:'单选'},{id:4,name:'选词填空'},{id:5,name:'左右栏'},{id:6,name:'情景交际'},{id:7,name:'阅读'},{id:8,name:'写作'}];
export {PRIMARY_TYPE_ARR};
let DEBTER = [{id:1,name:'第一学期'},{id:2,name:'第二学期'}];
export {DEBTER};
let TERM = [{id:1,name:'单元测评'},{id:2,name:'期中测评'},{id:3,name:'期末测评'}];
export {TERM}

let AllDEBTER = [{id:'',name:'全部'},{id:1,name:'第一学期'},{id:2,name:'第二学期'}];
export {AllDEBTER};
let AllTERM = [{id:'',name:'全部'},{id:1,name:'单元测评'},{id:2,name:'期中测评'},{id:3,name:'期末测评'}];
export {AllTERM}

let SERIAL_NUM = ["A", "B", "C", "D", "E", "F", "G"]; //选项编号
export {SERIAL_NUM}
let JUDGE_ITEM = [{ id: 1, name: '正确' }, { id: 0, name: '错误' }];
export {JUDGE_ITEM}